export const environment = {
  production: false,
  ipRecursos: 'https://phoenix-qa.belatec.pe',
  //local -> ipRecursos: 'http://phoenix-desa.belatec.pe',
  portRecursos: ':443',
  //local -> portRecursos: ':8084'
  //Servidor local
  //apiUrl: 'http://localhost',
  //port: ':3000'
  //servidor pruebas
  apiUrl:'https://phoenix-qa.belatec.pe', 
  port: ':443'
};
<div class="wrapper fadeInDown">
    <div id="formContent">
        <h2 class="active"> Autenticación de Usuario</h2>
        <br>
        <br>
        <div class="fadeIn first">
            <img src="./../../../../assets/icons/logo-belatec.png" id="icon" alt="Logo" style="width:80%;height:200px;" />
        </div>
        <br>
        <form [formGroup]="loginForm" (ngSubmit)="login()">
            <input #username type="text" class="fadeIn second" uppercase maxlength="50" formControlName="username" placeholder="Usuario*">
            <div *ngIf="submitted && f.username.errors">
                <div *ngIf="f.username.errors.required">
                    <p-message [style]="{'width':'100%'}" severity="error" text="El campo es requerido"></p-message>
                </div>
            </div>
            <input type="password" class="fadeIn third" maxlength="50" formControlName="password" placeholder="Password*">
            <div *ngIf="submitted && f.password.errors">
                <div *ngIf="f.password.errors.required">
                    <p-message severity="error" text="El campo es requerido"></p-message>
                </div>
            </div>
            <div *ngIf="userInvalid && !submitted">
                <p-message [style]="{'width':'100%'}" severity="info" text="Usuario no válido"></p-message>
            </div>
            <input type="submit" class="fadeIn fourth" value="Log In">
        </form>

        <div id="formFooter">
            <a class="underlineHover" (click)="restablecerContrasena()">Restabler contraseña</a>
        </div>
    </div>
</div>

<p-dialog header="Restablecer Contraseña" [(visible)]="display" [modal]="true" [style]="{width: '550px', minWidth: '350px'}" [minY]="70" [baseZIndex]="10000">
    <p>Se enviará un link para restablecer su contraseña a la siguiente dirección de correo electrónico :</p>
    <ul *ngFor="let mail of correo">
        <li>{{mail}}</li>
    </ul>
    <p-footer>
        <button type="button" pButton icon="pi pi-check" [disabled]="correoInvalido" (click)="confirmarRestablecerContrasena()" label="Confirmar"></button>
        <button type="button" pButton icon="pi pi-close" (click)="cancelarRestablecerContrasena()" label="Cancelar" class="ui-button-secondary"></button>
    </p-footer>
</p-dialog>
<header class="header-fixed">
  <form [formGroup]="headerForm">
    <div class="p-grid" style="align-items: center; font-size: 12px;">
      <!-- Columna 1: Logo + Compañía -->
      <div class="p-col-12 p-md-5">
        <div class="p-grid p-align-center">
          <div class="p-col-fixed" style="width: 30px;">
            <a class="logo" (click)="abrirMenu()">
              <img [src]="menu ? 'assets/icons/icon-menu.png' : 'assets/icons/icon-eliminar.png'" alt="Menu" style="width: 20px; cursor: pointer;" />
            </a>
          </div>
          <div class="p-col-fixed" style="margin-left: 5px;">Cía:</div>
          <div fxFlex="80">
            <p-dropdown [options]="listaCompania" formControlName="cia" filter="true"
              (onChange)="cambioCia()" placeholder="-- Seleccione --"
              optionLabel="DE_UNID_OPER" optionValue="ID_ORGN_EBS"
              [style]="{ width: '100%' }" [showClear]="false">
            </p-dropdown>
          </div>
        </div>
      </div>

      <!-- Columna 2: Fecha + TC + Usuario -->
      <div class="p-col-12 p-md-7">
        <div class="p-grid p-align-center">
          <div class="p-col-3" style="text-align: center;">
            <input type="text" pInputText formControlName="fecha" style="
              background-color: transparent;
              font-size: 12px;
              font-weight: bold;
              color: white;
              text-align: center;
              width: 100%;
            ">
          </div>
          <div class="p-col-1 tipoCambioHeader">T.C. Venta:</div>
          <div class="p-col-2 tipoCambioHeader">{{ tipoCambioVenta }}</div>
          <div class="p-col-1 tipoCambioHeader">T.C. Compra:</div>
          <div class="p-col-2 tipoCambioHeader">{{ tipoCambioCompra }}</div>
          <div class="p-col-3" style="text-align: right; flex: 1 1 auto; font-size: 13px; font-weight: bold;">
            <a (click)="op1.toggle($event)" style="cursor: pointer">
              <i class="pi pi-bell">
                <span class="label-warning">{{cantNoti}}</span>
              </i>
            </a>
            &nbsp;&nbsp;
            <a (click)="op2.toggle($event)" style="cursor: pointer">
              {{username}}<i class="pi pi-angle-down"></i>
            </a>

            <p-overlayPanel #op1>
              <div class="ui-g ui-fluid text-center">
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                    <span><b>Tienes {{cantNoti}} notificaciones</b></span>
                  </div>
                </div>
              </div>
              <ul class="alert-header" *ngFor="let noti of notificacion">
                <li>
                  <a class="list-noti" (click)="verNotificaciones()" title="{{noti.DE_NOTI}}">
                    <i class="fas fa-exclamation" style="color: red"></i>
                    &nbsp;{{noti.DE_NOTI | slice:0:30 }} ...
                  </a>
                </li>
              </ul>
              <li class="footer" (click)="verNotificaciones()">
                <a>Ver Todo</a>
              </li>
            </p-overlayPanel>

            <p-overlayPanel #op2>
              <div style="text-align: center">
                <img *ngIf="sexoUsuario == 'M'" src="assets/icons/masculino-72.png" alt="Sidebar" />
                <img *ngIf="sexoUsuario == 'F'" src="assets/icons/femenino-72.png" alt="Sidebar" />
                <p><strong>{{nombreUsuario}}</strong></p>
                <span><i class="fas fa-laptop"></i> {{ipTerminal}}</span><br>
                <span><i class="fas fa-sign-in-alt"></i> {{fechaLogin | date:'dd/MM/yyyy HH:mm:ss' }}</span>
                <br><br>
                <button pButton type="button" (click)="cambiarContrasena()" label="Cambiar Contraseña"
                  class="p-button-raised p-button-rounded"></button>
                <button pButton type="button" (click)="cerrarSession()" label="Cerrar Sesión"
                  class="p-button-raised p-button-rounded p-button-danger"></button>
              </div>
            </p-overlayPanel>
          </div>
        </div>
      </div>
    </div>
  </form>
</header>
import Swal, { SweetAlertIcon } from 'sweetalert2';

export function MensajeAlertError() {
    Swal.fire({
      icon: 'error', // Usar 'icon' en lugar de 'type'
      title: 'Oops...',
      html: '<b>Algo salió mal!</b> <br> Si el error continua, comuníquese con el área de Sistemas.',
    });
  }

export function MensajePhoenixInfo(mensaje: string) {
    Swal.fire({
      icon: 'info', // Usar 'icon' en lugar de 'type'
      title: 'Phoenix Informativo',
      html: mensaje,
    });
  }

export function MensajePhoenixErrorConLista(mensaje: any) {
    let msg: string = '<ul style="text-align:left">';
    for (let m of mensaje) {
      msg += `<li>${m.DE_ESTA}</li>`;
    }
    msg += '</ul>';
  
    Swal.fire({
      icon: 'error' as SweetAlertIcon, // Usar 'icon' en lugar de 'type'
      title: 'Phoenix Informativo',
      html: msg,
    });
  }

  export function MensajePhoenixError(mensaje: string) {
    Swal.fire({
      icon: 'error', // Usar 'icon' en lugar de 'type'
      title: 'Phoenix Informativo',
      html: mensaje,
    });
  }

  export function MensajePhoenixErrorConListaString(mensaje: any) {
    let msg: string = '<ul style="text-align:left">';
    for (let m of mensaje) {
      msg += `<li>${m}</li>`;
    }
    msg += '</ul>';
  
    Swal.fire({
      icon: 'error' as SweetAlertIcon, // Usar 'icon' en lugar de 'type'
      title: 'Phoenix Informativo',
      html: msg,
    });
  }

  export function MensajePhoenixConLista(title: any, mensaje: any) {
    let msg: string = '<ul style="text-align:left">';
    for (let m of mensaje) {
      msg += `<li>${m.DE_ESTA}</li>`;
    }
    msg += '</ul>';
  
    Swal.fire({
      icon: 'error' as SweetAlertIcon, // Usar 'icon' en lugar de 'type'
      title: title,
      html: msg,
    });
  }

  export function MensajePhoenixConListaTitleMsg(title: any, mensaje: any) {
    console.log('MensajePhoenixConListaTitleMsg', title, mensaje);
    let msg: string = '<ul style="text-align:left">';
    for (let m of mensaje) {
      msg += `<li>${m}</li>`;
    }
    msg += '</ul>';
  
    Swal.fire({
      icon: 'error' as SweetAlertIcon, // Usar 'icon' en lugar de 'type'
      title: title,
      html: msg,
    });
  }
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-table-form',
  templateUrl: './table-form.component.html',
  styleUrls: ['./table-form.component.scss']
})
export class TableFormComponent {

  @Input() parentForm: FormGroup;   // FormGroup que contiene el FormArray
  @Input() columns: any[] = [];     // Definición de columnas [{ header: 'Header Name', field: 'property', type: 'dropdown', options: [...] }]
  @Input() listaTipoDestinoCombo: any[] = [];
  @Input() listaSubinventarios: any[] = [];
  @Input() listaLocalizadores: any[] = [];
  @Input() idLinea: any;
  @Input() es: any;  // Localización para el calendario
  @Input() selectedRows: any[] = [];  // Fila seleccionada
  @Input() colSpanValue: number = 0;  // Fila seleccionada
  @Output() rowSelectionChange = new EventEmitter<any[]>();
  @Output() onRowSelectChange = new EventEmitter<any[]>();
  @Output() onRowUnselectChange = new EventEmitter<any[]>();
  @Output() onHandleInputChange = new EventEmitter<any[]>();  // Evento para manejar el cambio de input

  constructor() {}

  // Acceder al FormArray
  get tableRowArray(): FormArray {
    return this.parentForm.get('detalles') as FormArray;
  }

  emitOnRowSelectionChange(event:any, index:number): void {
    const data: any = { event: event, index: index};
    this.onRowSelectChange.emit(data);
  }

  emitOnRowUnselectionChange(event:any, index:number): void {
    const data: any = { event: event, index: index};
    this.onRowUnselectChange.emit(data);
  }

  // Seleccionar/Deseleccionar todas las filas
  // handleSelectAll(event: any): void {
  //   debugger
  //   if (event.checked) {
  //     this.selectedRows = [];
  //     this.tableRowArray.controls.map(c => {
  //       const fg = c as FormGroup;
  //       this.selectedRows.push(fg.controls);
  //     });
  //   } else {
  //     this.selectedRows = [];
  //   }
  //   this.emitSelectionChange();
  // }


  // Actualizar el FormGroup cuando se cambia el dropdown
  onDropdownChange(field: string, event: any, rowIndex: number): void {
    const control = this.tableRowArray.at(rowIndex).get(field);
    if (control) {
      control.setValue(event.value);  // Actualiza el valor del FormGroup
    }
  }

  // Actualizar el FormGroup cuando se selecciona una fecha
  onDateSelect(field: string, event: any, rowIndex: number): void {
    const control = this.tableRowArray.at(rowIndex).get(field);
    if (control) {
      control.setValue(event);  // Actualiza el valor del FormGroup
    }
  }

  // Manejar el cambio de input
  handleInputChange(field: string, rowData: any, rowIndex: number): void {
    // const control = this.tableRowArray.at(rowIndex).get(field);
    // if (control) {
    //   control.setValue(rowData[field]);  // Actualizar el valor del input en el FormGroup
    // }
    const form: any = this.tableRowArray.at(rowIndex);
    const data: any = { field: field, form: form, rowIndex: rowIndex };
    this.onHandleInputChange.emit(data);
  }

  
  selectRow(event:any, index: number){
    console.log('event', event, index);
    if (event.checked){
      this.emitOnRowSelectionChange(event, index);
    }else{
      this.emitOnRowUnselectionChange(event, index);
    }
    //debugger
  //   const row = this.recepcionForm.get('detalleRecepcionForm') as FormArray;
  //   if (event.checked){
  //     this.lineasSeleccionadas.push(index);
  //     if (row.controls[index].get("CO_TIPO_DEST").value == 'INVENTORY'){
  //       if (this.transfOrg){
  //         row.controls[index].get("CO_SUBI_TO").setValidators([Validators.required]);
  //         row.controls[index].get("CO_SUBI_TO").updateValueAndValidity();
  //       }else{
  //         event.value.required = true;
  //         row.controls[index].get("CO_SUBI").setValidators([Validators.required]);
  //         row.controls[index].get("CO_SUBI").updateValueAndValidity();
  //       }
  //     }else{
  //       event.value.required = false;
  //       row.controls[index].get("CO_SUBI").clearValidators();
  //       row.controls[index].get("CO_SUBI").updateValueAndValidity();
  //       row.controls[index].get("ID_LOCA").clearValidators();
  //       row.controls[index].get("ID_LOCA").updateValueAndValidity();
  //       row.controls[index].get("ID_LOCA_ENTR").clearValidators();
  //       row.controls[index].get("ID_LOCA_ENTR").updateValueAndValidity();
  //     }
  //   }else{
  //     this.lineasSeleccionadas = this.lineasSeleccionadas.filter(l => l != index);
  //     event.value.required = false;
  //     row.controls[index].get("CO_SUBI").clearValidators();
  //     row.controls[index].get("CO_SUBI").updateValueAndValidity();
  //     row.controls[index].get("ID_LOCA").clearValidators();
  //     row.controls[index].get("ID_LOCA").updateValueAndValidity();
  //   }
  //   this.sumarSeleccionados();
 }

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './component/util/table/table.component';
import { primeNgModule } from './app-primeng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UppercaseDirective } from './component/util/directivas/uppercase.directive';
import { NumberFormatDirective } from './component/util/directivas/number-format.directive';
import { MensajeComponent } from './component/util/message/mensaje/mensaje.component';
import { DatePersonalizePipe } from './component/util/pipe/DatePersonalize.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TableFormComponent } from './component/util/table-form/table-form.component';

@NgModule({
  declarations: [TableComponent, TableFormComponent, UppercaseDirective, NumberFormatDirective, MensajeComponent, DatePersonalizePipe],
  imports: [
    CommonModule,
    primeNgModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxEchartsModule,
    NgxChartsModule,
    FlexLayoutModule
  ], exports: [
    TableComponent,
    TableFormComponent,
    primeNgModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    UppercaseDirective,
    NumberFormatDirective,
    MensajeComponent,
    NgxEchartsModule,
    NgxChartsModule
  ]
})
export class GenericoModule { }

<form [formGroup]="parentForm">
  <p-table 
    #dt 
    [columns]="columns" 
    [value]="tableRowArray.controls" 
    [dataKey]="idLinea"
  >
    <!-- Encabezado de la tabla -->
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th [ngStyle]="{'width': '5%'}">
          <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> Checkbox para seleccionar todas las filas -->
        </th>
        <th *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>

    <!-- Cuerpo de la tabla -->
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
      <ng-container formArrayName="detalles">
        <tr [formGroupName]="rowIndex" [pSelectableRow]="rowData.controls">
          <!-- Checkbox de selección de fila -->
          <td [ngStyle]="{'width': '5%', 'text-align': 'center'}">
            <!-- <p-tableCheckbox [value]="rowData.controls" [pSelectableRow]="rowData.controls"></p-tableCheckbox> -->
            <p-tableCheckbox (click)="selectRow(e, rowIndex)" #e [value]="rowData.controls"></p-tableCheckbox>
          </td>

          <!-- Iteración sobre las columnas de la tabla -->
          <td *ngFor="let col of columns">
            <ng-container [ngSwitch]="col.type">
              
              <!-- Muestra de valor según tipo de columna -->
              <span *ngSwitchCase="'label'">{{ rowData.get(col.field).value }}</span>
              <span *ngSwitchCase="'number'">{{ rowData.get(col.field).value | number:'1.2' }}</span>
              <span *ngSwitchCase="'fecha'">{{ rowData.get(col.field).value | date:'dd/MM/yyyy' }}</span>
              <span *ngSwitchCase="'date'">{{ rowData.get(col.field).value | date:'dd/MM/yyyy HH:mm:ss' }}</span>

              <!-- Input de número -->
              <input 
                *ngSwitchCase="'number-input'" 
                pInputText 
                formControlName="{{ col.field }}"
                (input)="handleInputChange(col.field, rowData.value, rowIndex)"
                [ngStyle]="{'width': '100%', 'background': '#ffff8f'}"
              />

              <!-- Dropdown Tipo Destino -->
              <p-dropdown 
                *ngSwitchCase="'dropdown-tipo-dest'" 
                [options]="listaTipoDestinoCombo" 
                placeholder="-- Seleccione --"
                filter="true" 
                (onChange)="onDropdownChange(col.field, $event, rowIndex)" 
                [ngStyle]="{'width': '100%', 'background': '#ffff8f'}" 
                formControlName="{{ col.field }}"
              ></p-dropdown>

              <!-- Dropdown Subinventario -->
              <p-dropdown 
                *ngSwitchCase="'dropdown-subinventario'"
                [options]="listaSubinventarios" 
                placeholder="-- Seleccione --" 
                filter="true"
                (onChange)="onDropdownChange(col.field, $event, rowIndex)"
                [ngStyle]="{'width': col.width, 'background': '#ffff8f'}" 
                formControlName="{{ col.field }}"
              ></p-dropdown>

              <!-- Dropdown Localizador -->
              <p-dropdown 
                *ngSwitchCase="'dropdown-localizador'"
                [options]="listaLocalizadores" 
                placeholder="-- Seleccione --" 
                filter="true" 
                [showClear]="true"
                (onChange)="onDropdownChange(col.field, $event, rowIndex)"
                [ngStyle]="{'width': col.width, 'background': '#ffff8f'}" 
                formControlName="{{ col.field }}"
              ></p-dropdown>

              <!-- Calendario para selección de fecha -->
              <p-calendar 
                *ngSwitchCase="'date-input'" 
                formControlName="{{ col.field }}" 
                dateFormat="dd/mm/yy"
                [showIcon]="true" 
                [locale]="es" 
                appendTo="body" 
                showButtonBar="true" 
                [showTime]="false"
                (onSelect)="onDateSelect(col.field, $event, rowIndex)"
              ></p-calendar>

            </ng-container>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <!-- Pie de tabla -->
    <ng-template pTemplate="footer">
      <tr>
        <td [attr.colspan]="colSpanValue">
          Total de filas seleccionadas: {{ selectedRows.length }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</form>

import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MensajePhoenixError } from './message/UtilMessage';
import * as moment from 'moment';
import { FormGroup, Validators } from '@angular/forms';
import Swal, { SweetAlertIcon } from 'sweetalert2';

const periodos = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

export function fromStringToDate(fecha: string) {
    if (fecha && fecha != null && fecha.trim() != '') {
        const mes: any = fecha.split('-')[0];
        if (periodos.indexOf(mes) != -1) {
            let indice = 0;
            let numberMes = 0;
            periodos.forEach(periodo => {
                if (periodo == mes) {
                    numberMes = indice;
                }
                indice++;
            });
            const anio = Number.parseInt('20'.concat(fecha.split('-')[1]));
            return new Date(anio, numberMes, 1, 0, 0, 0);
        } else {
            return null;
        }
    }
}

export function fromDateToPeriodo(fecha: Date): any {
    return ([periodos[fecha.getMonth()], fecha.getFullYear().toString().slice(-2)].join('-'));
}

export function fromDateToString(fecha: Date): any {
    let fechaString = null;
    if ((fecha)) {
        const date = fecha;
        let dia = date.getDate();
        let diaString = '00';
        if (dia < 10) diaString = "0" + dia;
        else diaString = dia.toString();
        const mes = date.getMonth() + 1;
        let mesString = '00';
        if (mes < 10) mesString = '0' + mes;
        else mesString = mes.toString();
        const anio = date.getFullYear().toString();
        fechaString = diaString.concat('/').concat(mesString).concat('/').concat(anio);
    }
    return fechaString;
}


export function obtenerFechaYHorabyDate(dateParam: Date) {
    const date = dateParam;

    const fecha = fromDateToString(date);
    const horas = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutos = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const segundos = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    const fechaHora = fecha.concat(" ") + horas + ":" + minutos + ":" + segundos;
    return fechaHora;
}

export function obtenerFechaActual() {
    const date = new Date();

    const fecha = fromDateToString(date);
    const fechaHora = fecha.concat(" ") + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    return fechaHora;
}

export function renameProperty(obj: any, valor: any) {
    const value = obj[`${valor}`];
    delete obj[`${valor}`];
    return value;
};

export const calendarEspaniol = {
    firstDayOfWeek: 1,
    dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
    monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
    today: 'Hoy',
    clear: 'Borrar'
}

export function configuracionSwal(title: string, text: string, type: string, showCancelButton: boolean, confirmButtonText: string, cancelButtonText?: string): any {
    return {
        title: title,
        html: text,
        type: type,
        showCancelButton: showCancelButton,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonText,
        cancelButtonText: (cancelButtonText) ? cancelButtonText : 'Cancelar'
    };
}

export function selectOnlyOptionOfList(lista: any, this$: any, formName: string, fieldName: string) {
    const objeto = (lista && lista.length == 1) ? lista[0] : null;
    this$[formName].controls[fieldName].setValue(objeto || '');
}

export function selectOnlyOneOptionOfList(lista: any, this$: any, formName: string, fieldName: string) {
    const objeto = (lista && lista.length == 1) ? lista[0] : null;
    if ((objeto)) this$[formName].controls[fieldName].setValue(objeto || '')
    else null;
}

export function selectOnlyOneOptionOfListWithValue(
    lista: any[],
    this$: any,
    formName: string,
    fieldName: string
  ) {
    const objeto = (lista && lista.length === 1) ? lista[0] : null;
    console.log('selectOnlyOneOptionOfListWithValue → objeto:', objeto);
  
    const form = this$[formName];
    if (form && form.controls && form.controls[fieldName] && objeto) {
      form.controls[fieldName].setValue(objeto.value);
      console.log(`Valor seteado en ${fieldName}:`, objeto.value);
    } else {
      console.warn('No se pudo setear el valor, verifique que el control exista.');
    }
  }

export function selectOnlyOptionOfListGroup(lista: any, this$: any, formName: string, fieldName: string, fieldName2: string) {
    const objeto = (lista && lista.length == 1) ? lista[0] : null;
    this$[formName].get(fieldName).get(fieldName2).setValue(objeto || '');
}

export function redondearNumero(value: any, decimals: any) {
    value = value || 0;
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals).toFixed(decimals);
}

export function fixedNumber(value: number, decimals: number) {
    let resultado: any;

    switch (decimals) {
        case 1:
            resultado = Math.round(value * 10) / 10;
            break;
        case 2:
            resultado = Math.round(value * 100) / 100;
            break;
        case 3:
            resultado = Math.round(value * 1000) / 1000;
            break;
        case 4:
            resultado = Math.round(value * 10000) / 10000;
            break;
        case 5:
            resultado = Math.round(value * 100000) / 100000;
            break;
    }

    return resultado;
}

// export function getFilenameFromContentDisposition(contentDisposition: string, extension: string): string {
//     //
//     const regex = /; filename=(?<filename>[^,;]+);/g;
//     if (contentDisposition && contentDisposition.search("filename") != -1) {
//         const match = contentDisposition.split("; filename=");
//         const filename = (match) ? match[1] : `plantilla.${extension}`;
//         return filename;
//     } else {
//         return `plantilla.${extension}`;
//     }

// }

export function getFilenameFromContentDisposition(contentDisposition: string, extension: string): string {
  const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = regex.exec(contentDisposition);

  if (matches && matches[1]) {
    let filename = matches[1];
    // Remueve las comillas del nombre del archivo si están presentes
    if (filename.startsWith('"') && filename.endsWith('"')) {
      filename = filename.slice(1, -1);
    }
    return filename;
  } else {
    return `plantilla.${extension}`;
  }
}

export var reflectObject = async (objectNew: any, objectCurrent: any) => {
    for (let key of Object.keys(objectCurrent)) {
        if (key.substring(0, 2) == 'FE') objectNew[key] = objectCurrent[key] && new Date(objectCurrent[key]) || null;
        else objectNew[key] = objectCurrent[key] && objectCurrent[key] || null;
    }
    return objectNew;
}

export async function setLoadingSpinner(spinner: any, observable: Observable<any>) {
    spinner.show();
    return new Promise<void>((resolve, reject) => {
        observable
            .pipe(catchError((err) => { console.log(err); return throwError(err); }))
            .subscribe({
                next: (data) => { /*console.log(data)*/ },
                error: (e) => { /*console.log('error:::', e);*/ MensajePhoenixError(e + ''); spinner.hide() /*sólo si quiero que aparezca el error al usuario*/ },
                complete: () => { /*console.log('complete');*/ spinner.hide(); resolve(); }
            })
    })
}

export function setLoadingSpinner1(spinner: any, syncFunction: () => any) {
    spinner.show();
    try {
        const result = syncFunction();
        //console.log(result); // Puedes hacer algo con el resultado si es necesario
    } catch (error) {
        //console.log('error:::', error);
        MensajePhoenixError(error + '');
    } finally {
        //console.log('complete');
        spinner.hide();
    }
}

export function obtenerFechaActualHoraInicio() {
    const date = new Date();

    const fecha = fromDateToString(date);
    const fechaHora = fecha.concat(" ") + "00:00:00";
    console.log('fechaHora', fechaHora);
    //console.log(moment(fechaHora, "DD/MM/YYYY hh:mm:ss"));
    return moment(fechaHora, "DD/MM/YYYY hh:mm:ss").toDate();
}

export function obtenerFechaActualHoraFinal() {
    const date = new Date();

    const fecha = fromDateToString(date);
    const fechaHora = fecha.concat(" ") + "23:59:59";
    return moment(fechaHora, "DD/MM/YYYY hh:mm:ss").toDate();
}

export function obtenerFechaActualFormatoCompleto() {
    const date = new Date();
    const mes = calendarEspaniol.monthNames[date.getMonth()];
    const dia = calendarEspaniol.dayNames[date.getDay()];
    const annio = date.getFullYear();
    return `${dia}, ${date.getDate()} de ${mes} del ${annio}`;
}

export function exportExcel(params: any) {
    const data = params.data;
    const nombreArchivo = params.nombre;

    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, nombreArchivo);
    });
}

export async function exportarExcelv1(header: any, data: any[], workbook: any, worksheet: any) {
    // const header = params.header;
    // const data = params.data;

    // //const workbook = new Excel.Workbook();
    // const workbook = params.workbook;
    // const worksheet = params.worksheet;
    //workbook.lastPrinted = new Date(2016, 9, 27);
    //

    workbook.creator = 'Phoenix-ERP';
    workbook.lastModifiedBy = 'Rolando Márquez';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.properties.date1904 = true;

    workbook.views = [
        {
            x: 0, y: 0, width: 10000, height: 20000,
            firstSheet: 0, activeTab: 1, visibility: 'visible'
        }
    ];

    for (let i of header) {
        worksheet.addRow(i);
    }

    console.log('data', data);

    for (let i = 0; i < data.length; i++) {
        worksheet.addRow(data[i]);
    }

    return workbook;
}

export function saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver-es").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}

export function getParamsForUrl(params: any): string {
    let resultado: string = `?1=1`;
    for (let key of Object.keys(params)) {
        resultado += `&${key}=${encodeURIComponent(params[key])}`;
        //resultado += `&${key}=${params[key]}`;
    }
    return resultado;
}

export function convertirFecha(fecha: any) {
    let resultado: any = {};
    try {
        let date = new Date(fecha)
        let mes = ("0" + (date.getMonth() + 1)).slice(-2)
        let dia = ("0" + date.getDate()).slice(-2);
        resultado = [dia, mes, date.getFullYear()].join("/");
    } catch (e) {
        resultado = null;
    } finally {
        return resultado;
    }
}

export function obtenerPeriodo(fecha: any) {
    return moment(fecha).format('MMM-YY').toUpperCase();
}

export function toTitleCase(str: any) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export function numberWithCommas(x: number) {
    var parts = x.toFixed(2).toString().split(".");
    console.log('parts', parts);
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //if (!parts[1]){ parts[1] = '00'}else{ parts[1] = lpad(Number(parts[1]),2)}
    if (!parts[1]) { parts[1] = '00' }
    return parts.join(".");
}

export function stringANumero(stringConComas) {
    // Remueve las comas
    let stringLimpio = stringConComas.replace(/,/g, '');
    // Convierte el string limpio a un número
    let numero = parseFloat(stringLimpio);
    return numero;
}

export function validarEspaciosBlanco(input: string): boolean {
    // Verifica si el input es nulo o está vacío
    if (!input || input.trim() === '') {
        return false; // Si está vacío, retorna false
    }
    // Verifica si el input contiene espacios en blanco
    if (input.indexOf(' ') !== -1) {
        return true; // Si contiene espacios en blanco, retorna true
    }
    return false; // Si no contiene espacios en blanco, retorna false
}

export function obtenerOrganizacionLocalStorage (){
    //Se obtiene los datos PERO encriptados
    let ObjIdOrgn:any = {};
    let datosStorage:any = localStorage.getItem('data-header');
    let idOrgn: string;

    if (datosStorage){
      //Desencriptar el dato
      datosStorage= JSON.parse(atob(datosStorage));
      console.log('obtenerOrganizacionLocalStorage', datosStorage);
      idOrgn = datosStorage.ID_ORGN_EBS;
    }
    //Insertar el id de la organización en el objeto creado anteriormente
    ObjIdOrgn['idOrgnLS'] = idOrgn;
    //Retornar el objeto
    return ObjIdOrgn;
  }

  
export function seleccionarUnidadOperativa(form: FormGroup, companias: any[], idOrgnLS: number, params: any): void {
const unidadOperativaSeleccionada = companias.find(id => id.ID_ORGN_EBS === idOrgnLS);
console.log('unidadOperativaSeleccionada', unidadOperativaSeleccionada);
if (unidadOperativaSeleccionada) {
    form.controls['cia'].setValue(unidadOperativaSeleccionada);
    params.idUnidOper = unidadOperativaSeleccionada.ID_ORGN_EBS;
}
}

export function createControl(value: any, isDisabled: boolean, isRequired: boolean = true): any {
    return isRequired?[{value: value, disabled: isDisabled }, Validators.required] : [{value: value, disabled: isDisabled }];
  };

export function removeNullAttributes<T extends Record<string, any>>(obj: T): T {
const cleanedObj: Record<string, any> = {}; // Crear un nuevo objeto limpio

Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (value !== null && value !== undefined) {
    cleanedObj[key] = value; // Agregar solo valores válidos
    }
});

return cleanedObj as T; // Retornar el objeto limpio con el mismo tipo de entrada
}

export const mostrarAlerta = async (
    title: string,
    text: string,
    icon: SweetAlertIcon = 'info',
    showCancelButton: boolean = false,
    confirmButtonText: string = 'OK'
  ): Promise<any> => {
    return Swal.fire({
      title,
      text,
      icon,
      showCancelButton,
      confirmButtonText,
      cancelButtonText: showCancelButton ? 'Cancelar' : undefined,
    });
  };

  export function formatDateForUrl(date: Date): string {
    const pad = (num: number): string => num.toString().padStart(2, '0');
  
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Los meses empiezan desde 0
    const year = date.getFullYear();
  
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
  
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export function distinctByField<T>(items: T[], field: keyof T): T[] {
    return Array.from(
      new Map(items.map(item => [item[field], item])).values()
    );
  }
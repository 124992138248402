import { TransaccionesComponent } from "../component/core/negocio/ventas/transacciones/transacciones.component";

export const RoutingConst = {
  almacen: "/almacen",
  inventario: "/inventario",
  maestro: "/maestro",
  transporte: "/transporte",
  conductor: "/conductor",
  vehiculo: "/vehiculo",
  establecimiento: "/establecimiento",
  transferenciaInventario: "/transferencia-inventario",
  transferenciaVarias: "/transferencia-varias",
  localizador: "/localizador",
  finanza: "/finanza",
  cuentaContable: "/cuentas-contables",
  cuentaAlias: "/cuentas-alias",
  ubicacion: "/ubicacion",
  transaccionMaterial: "/transaccion-material",
  comprador: "/comprador",
  plantillaEta: "/plantilla-eta",
  pagos: "/pagos",
  comprasTipoLinea: "/compras-tipo-linea",
  compras: "/compras",
  ordenCompra: "/orden-compra",
  flujoOC: "/flujo-orden-compra",
  envioLocalOC: "/envio-local-orden-compra",
  distribucionOC: "/distribucion-orden-compra",
  ventas: "/ventas",
  transaccion: "/transaccion",
  tipoCambio: "/tipo-cambio",
  facturacion: "/facturacion",
  clasificacion: "/clasificacion",
  facturaProveedor: "/factura-proveedor",
  recepcion: "/recepcion",
  transaccionRecepcion: "/transaccion-recepcion",
  impuestoFactura: "/impuesto-factura",
  impuesto: "/impuesto",
  cheque: "/cheque",
  cuentaBanco: "/cuentas-banco",
  facturaPago: "/factura-pago",
  transaccionPago: "/transaccion-pago",
  transaccionFactura: "/transaccion-factura",
  organizacion: "/organizacion",
  proveedorCuentaBanco: "/proveedor-cuenta-banco",
  importaciones: "/importaciones",
  importacion: "/importacion",
  importacionAsociacion: "/importacion-asociacion",
  importacionDocumento: "/importacion-documento",
  proveedorContabilidad: "/proveedor-contabilidad",
  cuentasCobrar: "/cuentas-cobrar",
  transaccionVarias: "/transaccion-varias",
  reporteCostoAlmacen: '/reporte-costo-almacen'
};

export const CuentasCobrar = {
  resumenTransaccion: "/resumen-transacciones",
  recibos: "/recibos",
  facturaRecibo: "/factura-recibo",
  cronogramaPagos: "/cronograma-pagos",
  cronogramaDetalle: "/cronograma-detalle",
  //   listadoDocumentoByParams: "/listado-documento-by-params",
  //   listadoCabecera: "/listado-cabecera",
};
